import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/react"

export default () => (
  <StaticQuery
    query={graphql`
      query imageContactQuery {
        file(
          relativePath: { eq: "cabinet-medical-vauban-invalides-paris.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Img
        fluid={data.file.childImageSharp.fluid}
        alt="L'Espace Médical Vauban est situé à Paris 7ème arrondissement, en face du dôme des Invalides."
        css={css`
          width: 100%;
          transform: translateY(85px);
          z-index: -1;
          margin-top: -85px;
          @media (max-width: 1024px) {
            transform: none;
            margin-top: 0;
            border-radius: 7px 7px 0 0;
          }
        `}
      />
    )}
  />
)
